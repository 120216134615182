import React from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Faq6 = () => {
  return (
    <>
      <Layout>
        <Container>
          <Row>
            <div className="back-button mb-4 mt-3">
              <Link to="/faq/">&larr; Back</Link>
            </div>
            <Col sm="12">
              <div className="mb-5">
                <StaticImage
                  src="../../images/woman-orange-background.jpg"
                  alt="Woman"
                  placeholder="none"
                  layout="fullWidth"
                />
                <h2 className="mt-3">
                  What can I expect when I go to the dentist?
                </h2>
                <p>
                  While every oral health situation is unique and your
                  experience may vary, most Lumineers<sup>&reg;</sup> procedures
                  are completed in just a few easy visits.
                </p>
              </div>
              <div className="mb-5 text-center">
                <Link to="../../procedure/" className="intro-button mb-5">
                  Learn More
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Faq6
